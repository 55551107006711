.user_card {
  border-color: #eee !important;
  overflow-x: scroll;
}
.user_card::-webkit-scrollbar {
  height: 7px;
}

.user_card::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.user_card::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}
.pagnte{
  margin-top: 30px !important;
  background-color: #fff;
  margin: 0px auto;
  width: 150px;
  border: none;
  box-shadow: 1px 1px #f1ebeb;
}
.pagnte .page-link {
  border: none;
}