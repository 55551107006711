.player__info {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  flex-shrink: 0;
  margin: 0 auto;
  padding-top: 10px;
  gap: 4px;
  width: 785px;
}

.player_data_info {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  flex-shrink: 0;
  margin: 0 auto;
  padding-top: 4px;
  gap: 4px;
  width: 785px;
  height: 15px;
}

.vis_st_data {
  padding-left: 15px;
  color: black;
}
.vis__el {
  background: #d2dae4;
  padding: 10px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 0;
}
/* var(--vis-grey); */
.player__version {
  font-size: 13px;
}

.vis__data {
  height: 30px;
  padding-top: 5px;
  padding-left: 1px;
  display: flex;
  align-items: center;
  font-weight: medium;
  color: black;
}

.vis__title {
  text-transform: uppercase;
  font-size: 10.5px;
  font-weight: 600;
  border-radius: 4px;
  padding: 3px 5px;
  color: #fff;
  background: black;
}

/* var(--vis-grey); */
.btn {
  color: #6cbd46;
  border-color: #6cbd46;
}

.btn-circle.btn-sm {
  background-color: #8ca281 !important;
  outline: none !important;
  border: none;
}
.btn-circle.btn-sm:focus {
  border: none;
  box-shadow: none;
}
.stream-btn {
  background-color: #6cbd46;
  height: 60px;
  width: 150px;
  border-radius: 10px !important;
  border: none;
}
.stream-btn:hover {
  background-color: #3aac42;
}
.stream-btn.danger {
  background-color: #d53343 !important;
}
.controls-toolbar-wrapper {
  display: flex;
  margin: 0 auto;
  width: 100%;
}

@media screen and (max-width: 991px) {
  .controls-toolbar-wrapper {
    width: 100%;
  }
}
.stream_btn_container {
  width: 60px;
  height: 60px;
  padding: 16px 17px;
  border-radius: 50%;
  background: #3aac42;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  margin: 5px;
}
.stream_btn_container:hover {
  background-color: #6cbd46;
}
.stream_btn {
  width: 28px;
  height: 28px;
  background: #f0f0f0;
  transition: inherit;
  clip-path: polygon(
    0 0,
    50% 25%,
    50% 75%,
    50% 75%,
    50% 25%,
    100% 50%,
    100% 50%,
    0 100%
  );
  border: none;
  border-radius: 3px;
}
.stream_btn_container.playing .stream_btn {
  clip-path: polygon(
    0 0,
    50% 0,
    50% 100%,
    50% 100%,
    50% 0,
    100% 0,
    100% 100%,
    0 100%
  );
}
.stream_btn_container.danger {
  background-color: #d53343;
}
