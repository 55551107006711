.show-table{
    border-color: #eee !important;
    overflow-x: scroll;
}
.purchase-cont{
    margin-bottom: 30px;
}
.paging__link--active {
    font-size: 1.2rem; 
    font-weight: 700;
    color: #047484;
  }