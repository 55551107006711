.react-tabs {
  display: flex;
  width: 900px;
}

.react-tabs__tab-list {
  display: flex;
  flex-direction: column;
  width: 25%;
  margin: 50px;
  margin-left: 60px;
  padding: 0;
  border-bottom: none !important;
  border-right: 3px solid #c5d5bd;
}

.react-tabs__tab {
  height: 50px;
  list-style: none;
  padding: 12px 6px;
  cursor: pointer;
  color: #bbb;
  width: 100%;
}

.react-tabs__tab--selected {
  border: none !important;
  background-color: #c5d5bd !important;
  border-radius: 0px !important;
  height: 50px;
  outline: none;
  padding: 10px 0;
}
.admin_pro{
 margin-left: 4% !important;
 font-size: 25px;
}
.admin_pro1{
  text-align: center;
}

.react-tabs__tab-panel {
  display: none;
}

.react-tabs__tab-panel--selected {
  display: block;
  width: 100%;
}

.react-tabs__tab {
  padding-left: 24px;
}
.react-tabs__tab--selected {
  padding-left: 21px;
}

.panel-content {
    width: 100%;
  padding: 0 20px;
  text-align: left;
}

.profile_card{
    width: 100%;
    overflow: hidden !important;
    padding: 20px;
}
.profile_pic{
    display: block;
    margin: 0 auto;
    width: 120px;
    height: 120px;
    background-color: #eee;
    border-radius: 50%;
}

.panel-content{
  overflow: hidden;
}
.set_btn{
 margin-left: 15px;
 font-weight: 500;
  font-size: 17px;
 color: black;
  border-radius: 5px;
  border: none;
  outline: none;
  background-color: #c5d5bd;
}

/* @media screen and (max-width: 766px){
  
  .card{
    margin-right: 100px !important;
    overflow: hidden;
  }
  .panel-content{
    width: 60%;
    height: 700px;
  }

}
@media screen and (max-width: 686px){
  .react-tabs__tab-list{
    width: 90px;
  }
  .card{
    margin-right: 100px !important;
  }
  .panel-content{
    width: 50%;
  }

}
@media screen and (max-width: 606px){
  .panel-content{
    width: 40%;
  }
  .react-tabs__tab-list{
    width: 70px;
  }
  .card{
    margin-right: 100px !important;
    overflow: hidden;
  }

}
@media screen and (max-width: 491px){
  .panel-content{
    width: 32%;
  }
  .react-tabs__tab-list{
    width: 70px;
  }
  .card{
    margin-right: 100px !important;
    overflow: hidden;
  }
} */