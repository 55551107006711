@mixin better-blur($radius) {
  filter: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='a' x='0' y='0' width='1' height='1'%3E%3CfeGaussianBlur stdDeviation='#{$radius}' result='b'/%3E%3CfeMorphology operator='dilate' radius='#{$radius}'/%3E %3CfeMerge%3E%3CfeMergeNode/%3E%3CfeMergeNode in='b'/%3E%3C/feMerge%3E%3C/filter%3E%3C/svg%3E#a");
}

.call-view {
  width: 100%;
  height: calc(100vh - 200px);
}

/**
   * Call view controls section
   */
.call-view__controls-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  pointer-events: none;
  z-index: 2;

  .call-view__controls {
    // width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 120px;
  //   padding-bottom: 32px;
  padding-bottom: 48px;

    /* Generated with https://larsenwork.com/easing-gradients */
  //   background: linear-gradient(
  //     to top,
  //     hsla(0, 0%, 0%, 0.25) 0%,
  //     hsla(0, 0%, 0%, 0.247) 8.1%,
  //     hsla(0, 0%, 0%, 0.238) 15.5%,
  //     hsla(0, 0%, 0%, 0.224) 22.5%,
  //     hsla(0, 0%, 0%, 0.206) 29%,
  //     hsla(0, 0%, 0%, 0.185) 35.3%,
  //     hsla(0, 0%, 0%, 0.162) 41.2%,
  //     hsla(0, 0%, 0%, 0.137) 47.1%,
  //     hsla(0, 0%, 0%, 0.113) 52.9%,
  //     hsla(0, 0%, 0%, 0.088) 58.8%,
  //     hsla(0, 0%, 0%, 0.065) 64.7%,
  //     hsla(0, 0%, 0%, 0.044) 71%,
  //     hsla(0, 0%, 0%, 0.026) 77.5%,
  //     hsla(0, 0%, 0%, 0.012) 84.5%,
  //     hsla(0, 0%, 0%, 0.003) 91.9%,
  //     hsla(0, 0%, 0%, 0) 100%
  //   );

    .call-view__controls__icon-btn {
      height: 64px;
      width: 64px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 8px;
      transform: scale(1);
      transition: transform 200ms cubic-bezier(0, 0, 0.2, 1);

      // background-color: #fafafa;
      background-color: red;
      color: #fff;
      border-radius: 50%;

      pointer-events: all;
      cursor: pointer;

      &:hover {
        transform: scale(1.25);
      }

      &.important {
        background-color: #ff3346;
      }

      & > i {
        font-size: 32px;
        width: 32px;
      }
    }
  }
}

/**
   * Call view tracks section
   */
.call-view__tracks {
  width: 100%;
  height: 100%;
  display: flex;
}

.remote-track--container {
  position: relative;
  flex: 1;
}

// .remote-track--picture-placeholder--container.carousel-item {
//   height: 400px;
// }

.remote-track--picture-placeholder--container
  > div:first-child:not(.no-video-live-playback) {
  width: 100%;
  height: 100%;
}

.remote-track--picture-placeholder--container {
  width: 100%;
  height: 100%;
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  background: black;

  .remote-track--picture-placeholder__background {
    width: 100%;
    height: 100%;

    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    @include better-blur(16);
  }

  .remote-track--picture-placeholder {
    width: 196px;
    height: 196px;
    position: absolute;
    border-radius: 50%;

    -webkit-box-shadow: 0px 0px 64px -16px rgba(0, 0, 0, 0.6);
    -moz-box-shadow: 0px 0px 64px -16px rgba(0, 0, 0, 0.6);
    box-shadow: 0px 0px 64px -16px rgba(0, 0, 0, 0.6);

    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    &.speaking {
      border: 6px solid #4caf50;
    }
  }
}

.call-view__tracks__local-track-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding: 40px;

  z-index: 1;

  .call-view__tracks__local-track {
    width: 350px;
    height: 180px;
    position: relative;
    border-radius: 16px;
    overflow: hidden;
    background: #78909c;

    -webkit-box-shadow: 0px 0px 64px -16px rgba(0, 0, 0, 0.6);
    -moz-box-shadow: 0px 0px 64px -16px rgba(0, 0, 0, 0.6);
    box-shadow: 0px 0px 64px -16px rgba(0, 0, 0, 0.6);

    video {
      width: 100%;
      height: 100%;
      z-index: 1;

      background: #4caf50;
    }
  }
}

/**
   * Input status section
   */
.input-status-container {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  border-bottom: 4px solid #fafafa;
  border-right: 4px solid #fafafa;
  border-radius: 0px 0px 16px 0px;
  overflow: hidden;

  .input-status {
    height: 48px;
    width: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ff3346;

    & > i {
      font-size: 28px;
    }
  }
}

.video-dropdown option:hover {
  background: red;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: -5px;
  left: 105%;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}


//   .btn-circle.btn-sm {
//     width: 60px;
//     height: 60px;
//     // padding: 6px 0px;
//     border-radius: 30px;
//     font-size: 8px;
//     text-align: center;
//     margin: 6px;
// }

.btn-circle.btn-sm {
width: 60px;
height: 60px;
// padding: 6px 0px;
border-radius: 30px;
font-size: 8px;
text-align: center;
margin: 6px;
color: #ffff;
border: 0px;
}

.btn-focus {
background: red;
}

.btn-focus:focus {
background: red;
}

.btn-focus:hover {
background: red;
}


.btn-circle.btn-sm:disabled {
width: 60px;
height: 60px;
// padding: 6px 0px;
border-radius: 30px;
font-size: 8px;
text-align: center;
margin-top: 15px;

}

.btn-circle.btn-action {
padding: 10px 10px;
border-radius: 30px;
text-align: center;
color: #8d9ca7;
margin-left: 15px;
color: #fff;
}

.btn-circle.btn-action:disabled {
padding: 10px 10px;
border-radius: 30px;
text-align: center;
color: #8d9ca7;
background: #cfd0d1;
margin-left: 15px;
}


.publish-btn {
  margin-top: 30px;
}


.controls-toolbar-wrapper {
  width: 70%;
margin: 0 auto;
display: block;
}

.tooltip-style {
background: #000;
color: #fff;
border-radius: 6px;
margin-top: 20px;
}

.material-icons-round {
font-size: 30px;
}