.navbar {
  position: fixed;
  width: 100%;
  background-color: #ededed;
  height: 50px !important;
  border-bottom: 1px solid rgba(204, 204, 204, 0.467);
  overflow: hidden;
  z-index: 10;
}
.side-bar {
  position: fixed;
  float: left;
  width: 250px;
  min-height: 100%;
  background-color: #fff;
  box-shadow: 0 5px 10px #7777;
  transition: all 0.2s linear;
  z-index: 99;
  left: 0;
  overflow: hidden;
}
.nav-logo {
  width: 150px;

  margin: 0 auto;
  display: block;
  padding: 10px;
}
.nav-logo > img {
  max-width: 100%;
  margin-top: 20px;
}
.nav-logo > label {
  align-items: center;
  justify-content: center;
}
.side-bar > ul {
  list-style: none;
  padding: 0;
}
.side-bar ul li {
  position: relative;
  width: 100%;
  padding: 10px 0;
  transition: all 0.5s ease-in-out;
}
.side-bar > i {
  position: absolute;
  right: 10px;
  color: #747474;
  cursor: pointer;
}
.nav-links {
  position: relative;
  margin: 10px 0;
  padding: 0 8px;
  color: #252525;
  font-size: 15px;
  text-decoration: none;
  white-space: nowrap;
}
.nav-links:hover {
  color: #047484;
}
.nav-links > i {
  padding-right: 25px;
  font-size: 20px;
}
.links.active {
  background-color: #047484;
}

.links.active > .nav-links {
  color: #ffffff;
}

.links > .tooltip_ {
  opacity: 0;
  position: absolute;
  color: #000;
  z-index: 99;
  font-size: 12px;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  transition: opacity 0.2s ease-in-out;
  cursor: pointer;
}
.tooltip_.active {
  color: #fff;
}
.user_ {
  position: absolute;
  width: 37px;
  height: 37px;
  right: 20px;
  border-radius: 50%;
  cursor: pointer;
  justify-content: center;
  overflow: hidden;
}
.user_ Button {
  width: 100px !important;
  margin: 0 auto;
  margin-left: -31px;
  margin-top: -5px;
  height: 50px !important;
  font-size: 20px;
}
.bars {
  width: 50px;
  height: 50px;
  position: relative;
  font-size: 30px;
  padding: 0 20px;
  color: #95a28f;
  cursor: pointer;
}
#check {
  position: absolute;
  opacity: 1;
  top: 0;
  cursor: pointer;
  left: 50%;
}
.nav-logo > #logoImg2 {
  display: none;
}
.mob_logo {
  width: 120px;
  height: 100%;
  /* background-color: #252525; */
  margin: 0 auto;
  display: block;
  background-image: url("../../assets/Eastman/Eastman-logo1.png");
  background-size: cover;
  display: none;
  transition: all 0.2s ease-in-out;
}
/* Responsive */
@media screen and (max-width: 1200px) {
  .nav-logo {
    display: none;
  }
  .side-bar {
    width: 70px;
    padding: 15px 0;
  }
  .side-bar ul li {
    padding: 20px;
    margin-top: 10px;
  }
  .links:hover > .tooltip_ {
    opacity: 1;
  }
  .nav-logo > #logoImg1 {
    display: none;
  }
  .mob_logo {
    display: block;
  }
}
.react-confirm-alert-button-group > button {
  width: 100px;
  height: 35px;
  font-weight: 500;
  font-size: 20px !important;
  padding: 0px !important;
}
.react-confirm-alert-overlay {
  background: #047484;
  color: #fff;
}
.clear-button {
  background-color: #252525;
}
.confirm-btn {
  background-color: #047484 !important;
  color: #fff;
}
