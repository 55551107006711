.all{
    text-align: left;
}
.live_cards{
    border: 1px solid #e1e0e0;
    box-shadow: none;
    overflow: hidden;
    padding: 10px;
    transition: all .2s ease-in-out;
    width: 95%;
}
.view_btn{
    text-align: center;
    font-size: 13px;
    color: #fff;
    background-color: rgb(22, 124, 22);
    border: none;
    outline: none;
    align-items: center;
    width: 70px;
    height: 30px;
    font-weight: 500;
    margin: 0px auto;
}
.button_live{
    background-color: #ee4744;
    font-size: 12px;
    padding: 5px;
    outline: none;
    position: relative;
    border: 0;
    color: #fff;    
    right: 10;
    border-radius: 2px;
    float: right;
    text-align: center;
}
.live_cards label{
    padding-right: 10px;
}
.live_bnt{
    border-radius: 2px;
    color: #fff;
}
.bolder{
    color: black;
    font-size: 15px;
    margin: 0px 0px 8px;
    padding: 0 10px 0 0;
    font-weight: bolder;

}