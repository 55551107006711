.add_btn {
  position: relative;
  float: right;
  width: 100px !important;
  height: 50px !important;
  margin-bottom: 30px !important;
  font-size: 20px;
  background-color: #252525;
  color: #fff;
  border-radius: 5px;
  z-index: 1;
  outline: none;
  border: none;
  overflow: hidden;
  transition: all 0.2s linear;
}
.add_btn::after,
.add_btn::before {
  position: absolute;
  content: "";
  -webkit-transition: all 0.35s ease-in-out 0s;
  -moz-transition: all 0.35s ease-in-out 0s;
  -ms-transition: all 0.35s ease-in-out 0s;
  -o-transition: all 0.35s ease-in-out 0s;
  transition: all 0.35s ease-in-out 0s;
}
.red {
  color: red;
}
.add_btn::before {
  top: 0;
  left: 0;
  width: 120%;
  height: 100%;
  z-index: -1;
  background-color: #047484;
  color: white !important;
  -webkit-transform: rotate3d(0, 0, 1, -45deg) translate3d(0, -4em, 0);
  transform: rotate3d(0, 0, 1, -45deg) translate3d(0, -4em, 0);
  -webkit-transform-origin: 0% 100%;
  transform-origin: 0% 100%;
  -webkit-transition: all 0.35s ease-in-out 0s;
  -moz-transition: all 0.35s ease-in-out 0s;
  -ms-transition: all 0.35s ease-in-out 0s;
  -o-transition: all 0.35s ease-in-out 0s;
  transition: all 0.35s ease-in-out 0s;
}
.add_btn::after {
  opacity: 1;
  -webkit-transform: rotate3d(0, 0, 1, 0deg);
  transform: rotate3d(0, 0, 1, 0deg);
  -webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
  transition-timing-function: cubic-bezier(0.2, 1, 0.1, 1);
}
.add_btn:hover::before {
  opacity: 1;
  -webkit-transform: rotate3d(0, 0, 1, 0deg);
  transform: rotate3d(0, 0, 1, 0deg);
  -webkit-transition-timing-function: cubic-bezier(0.2, 0.5, 0.3, 1);
  transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}
.table-co {
  padding-bottom: 50px !important;
}
.add_btn:hover {
  color: #252525;
}
.instructors_tbl {
  width: 100%;
}

.inst_card {
  overflow: hidden;
  border-color: #e2e5e0;
  margin-bottom: 50px !important;
}
.inst_card::-webkit-scrollbar {
  height: 7px;
  width: 5px;
}

.inst_card::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px #95a28f;
}

.inst_card::-webkit-scrollbar-thumb {
  background-color: #95a28f;
  outline: 1px solid #95a28f;
}

.modal_bg {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.364);
  backdrop-filter: blur(2px);
  z-index: 9;
  animation: fadein 0.5s;
  -webkit-transition: fadein 0.5s ease-in-out;
  -moz-transition: fadein 0.5s ease-in-out;
  transition: fadein 0.5s ease-in-out;
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.joint {
  display: flex !important;
}

.close {
  cursor: pointer;
  float: right;
  font-size: 20px;
}
.form-select {
  border-color: #8cd1db !important;
  box-shadow: none !important;
  outline: 0 none !important;
}
.text_fld {
  width: 80px !important;
  height: 37px !important;
  border: 1px solid #8cd1db !important;
  border-radius: 5px;
  text-decoration: none;
}
.text_fld :hover {
  text-decoration: none;
  pointer-events: none;
}

.form-control {
  border-color: #8cd1db !important;
}
.bio {
  width: 100%;
  height: 100px;
  border-color: #8cd1db;
  border-radius: 10px;
  padding: 10px 13px;
}
.add_form {
  border: none;
}

.form_btn {
  position: relative;
  display: block;
  margin: 0px auto;
  width: 30%;
  height: 50px;
  font-size: 20px;
  background-color: #047484;
  color: #fff;
  border-radius: 5px;
  z-index: 1;
  overflow: hidden;
  outline: none;
  border: none;
  transition: all 0.2s linear;
}
.form_btn::after,
.form_btn::before {
  position: absolute;
  content: "";
  -webkit-transition: all 0.35s ease-in-out 0s;
  -moz-transition: all 0.35s ease-in-out 0s;
  -ms-transition: all 0.35s ease-in-out 0s;
  -o-transition: all 0.35s ease-in-out 0s;
  transition: all 0.35s ease-in-out 0s;
}
.form_btn::before {
  top: 0;
  left: 0;
  width: 150%;
  color: black;
  height: 100%;
  z-index: -1;
  background-color: #8cd1db;
  -webkit-transform: rotate3d(0, 0, 1, -45deg) translate3d(0, -4em, 0);
  transform: rotate3d(0, 0, 1, -45deg) translate3d(0, -4em, 0);
  -webkit-transform-origin: 0% 100%;
  transform-origin: 0% 100%;
  -webkit-transition: all 0.35s ease-in-out 0s;
  -moz-transition: all 0.35s ease-in-out 0s;
  -ms-transition: all 0.35s ease-in-out 0s;
  -o-transition: all 0.35s ease-in-out 0s;
  transition: all 0.35s ease-in-out 0s;
}
.card {
  margin-left: 3%;
}
.form_btn::after {
  opacity: 1;
  -webkit-transform: rotate3d(0, 0, 1, 0deg);
  transform: rotate3d(0, 0, 1, 0deg);
  -webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
  transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}
.form_btn:hover::before {
  opacity: 1;
  -webkit-transform: rotate3d(0, 0, 1, 0deg);
  transform: rotate3d(0, 0, 1, 0deg);
  -webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
  transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}
.form_btn:hover {
  color: white;
}
.modal_bg.show {
  display: block;
}

.eye_btn {
  text-align: center;
} /* Add instructor section */

.profile {
  display: block;
  width: 150px;
  height: 150px;
  background-color: #7777;
  margin-left: 20px;
  border-radius: 50%;
  margin: 0 auto;
  overflow: hidden;
}
.table {
  padding: 10px;
}

.main {
  margin-left: 5% !important;
  margin-right: 5% !important;
}
.main > h2 {
  text-align: center;
}
.back_btn {
  background-color: #047484;
  border: none;
  outline: none;
  width: 50px;
  color: white;
  height: 50px;
  border-radius: 50%;
}

.add_modal {
  position: absolute;
  left: 50%;
  top: 50%;
  background-color: #fff;
  transform: translate(-50%, -50%);
  border-radius: 5px;
  padding: 20px;
}
.errormsg {
  color: red;
  padding-top: 10px;
}

.otherInput {
  overflow: hidden;
  height: 80px;
  margin-top: 5px;
  transition: height 0.5s ease-in-out;
}
.otherInput.show {
  height: 0;
}
.messa {
  margin-left: 5px !important;
}
.icon_socialmedia {
  font-size: 27px;
  color: #4e4c4c;
  margin-bottom: 50px !important;
  margin-left: 7px !important;
}
.icon_socialmedia :hover {
  background-color: #fff;
  color: #000;
}
.socialmedia {
  font-size: 27px;
  color: #4e4c4c;
  padding: 0 !important;
  margin: 10px !important;
}

.edit_btn {
  position: relative;
  float: center;
  width: 190px;
  margin-right: 10px;
  text-align: center;
  height: 40px;
  font-size: 20px;
  overflow: hidden;
  background-color: #047484;
  color: #fff;
  border-radius: 5px;
  z-index: 1;
  outline: none;
  border: none;
  transition: all 0.2s linear;
  margin-bottom: 15px;
  /* last margin-bottom add instructorsil illa */
}
.wid {
  background-color: #d9534f;
  z-index: 1;
  color: #ffffff;
  border-radius: 5px;
  outline: none;
  border: none;
  transition: all 0.2s linear;
  margin-bottom: 15px;
  margin-right: 10px;
  text-align: center;
  height: 40px;
  font-size: 20px;
  overflow: hidden;
  padding: 0 10px;
}

.edit_btn::after,
.edit_btn::before {
  position: absolute;
  content: "";
  -webkit-transition: all 0.35s ease-in-out 0s;
  -moz-transition: all 0.35s ease-in-out 0s;
  -ms-transition: all 0.35s ease-in-out 0s;
  -o-transition: all 0.35s ease-in-out 0s;
  transition: all 0.35s ease-in-out 0s;
}
.edit_btn::before {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: #fff;
  -webkit-transform: rotate3d(0, 0, 1, -45deg) translate3d(0, -4em, 0);
  transform: rotate3d(0, 0, 1, -45deg) translate3d(0, -4em, 0);
  -webkit-transform-origin: 0% 100%;
  transform-origin: 0% 100%;
  -webkit-transition: all 0.35s ease-in-out 0s;
  -moz-transition: all 0.35s ease-in-out 0s;
  -ms-transition: all 0.35s ease-in-out 0s;
  -o-transition: all 0.35s ease-in-out 0s;
  transition: all 0.35s ease-in-out 0s;
}
.edit_btn::after {
  opacity: 1;
  -webkit-transform: rotate3d(0, 0, 1, 0deg);
  transform: rotate3d(0, 0, 1, 0deg);
  -webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
  transition-timing-function: cubic-bezier(0.2, 1, 0.1, 1);
}
.edit_btn:hover::before {
  opacity: 1;
  -webkit-transform: rotate3d(0, 0, 1, 0deg);
  transform: rotate3d(0, 0, 1, 0deg);
  -webkit-transition-timing-function: cubic-bezier(0.2, 0.5, 0.3, 1);
  transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}
.edit_btn:hover {
  color: #047484;
  background-color: red;
  border: 1px solid #047484;
}

.buttons {
  margin-top: 20px !important;
}
th {
  border-bottom: 1px solid rgb(186, 186, 186);
}
.pro {
  border-bottom: 1px solid rgb(220, 215, 215);
}

.btsuc {
  margin: 5px !important;
  background-color: #198754 !important;
  height: 40px;
  width: 40px;
  border: none;
  outline: none;
  color: #ffffff !important;
  padding: 10 !important;
}
.therapist_enaDis {
  margin: 5px;
  background-color: #0072bb;
  height: 40px;
  width: 40px;
  border: none;
  outline: none;
  color: #ffffff;
  padding: 10;
  border-radius: 3px;
  transition: all 0.2s ease-in-out;
}
.therapist_enaDis.red {
  background-color: #dc3545;
}
.status {
  font-size: 25px;
  padding: 0 10px;
}
.status.red {
  color: #ce292a;
}
.status.green {
  color: #188351;
}
@media screen and (max-width: 600px) {
  .add_modal {
    font-size: 14px !important;
  }
}
